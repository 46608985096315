import React from "react"
import { Link } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import withRoot from "../withRoot"
import IconButton from "@material-ui/core/IconButton"
import { FaInstagram, FaTicketAlt, FaEnvelope } from "react-icons/fa"

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    paddingBottom: theme.spacing.unit * 5,
    paddingTop: theme.spacing.unit * 5,
    textAlign: "center",
  },
  margin: {
    margin: theme.spacing.unit,
  },
})

const Footer = ({ classes }) => {
  return (
    <footer className={classes.root}>
      <a href="https://www.instagram.com/knsrbeach/?hl=nl">
        <IconButton aria-label="Instagram" className={classes.margin}>
          <FaInstagram color="white" size="1.3em" />
        </IconButton>
      </a>
      <Link to="/tickets/">
        <IconButton aria-label="Facebook" className={classes.margin}>
          <FaTicketAlt color="white" size="1.3em" />
        </IconButton>
      </Link>
      <a href="mailto:knsrbeach@skadi.nl">
        <IconButton aria-label="Facebook" className={classes.margin}>
          <FaEnvelope color="white" size="1.3em" />
        </IconButton>
      </a>
    </footer>
  )
}
export default withRoot(withStyles(styles)(Footer))
