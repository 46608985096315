import { SheetsRegistry } from "jss"
import { createMuiTheme } from "@material-ui/core/styles"
import { createGenerateClassName } from "@material-ui/styles"

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ed5724",
      light: "#50659e",
      dark: "#001643",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffc551",
      light: "#f76992",
      dark: "#ffab03",
      contrastText: "#fff",
    },
    background: {
      default: "#9270ad",
    },
    text: {
      primary: "#ffffff",
    },
  },
  typography: {
    useNextVariants: true,
  },
})

function createPageContext() {
  return {
    theme,
    // This is needed in order to deduplicate the injection of CSS in the page.
    sheetsManager: new Map(),
    // This is needed in order to inject the critical CSS.
    sheetsRegistry: new SheetsRegistry(),
    // The standard class name generator.
    generateClassName: createGenerateClassName(),
  }
}

export default function getPageContext() {
  // Make sure to create a new context for every server-side request so that data
  // isn't shared between connections (which would be bad).
  if (!process.browser) {
    return createPageContext()
  }

  // Reuse context on the client-side.
  if (!global.__INIT_MATERIAL_UI__) {
    global.__INIT_MATERIAL_UI__ = createPageContext()
  }

  return global.__INIT_MATERIAL_UI__
}
